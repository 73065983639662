<template>
    <div class="file-block" :class="{empty: !fileUrl}" :id="`block-${block.id}`">
        <template v-if="fileUrl">
            <a class="label" :href="fileName.match(/\.(jpg|jpeg|png|gif|pdf)$/i) ? fileUrl : `/build${fileUrl.substring(fileUrl.lastIndexOf('.ro')+3)}`" target="_blank" :download="fileName.match(/\.(jpg|jpeg|png|gif|pdf)$/i) ? false : fileName">
                <div class="icon"><icon-file /></div>
                <div class="name">{{ fileName }}</div>
                <div class="loader" v-if="percentLoader"><div class="loader-spin"></div>  {{ percentLoader }}%</div>
                <div class="error-msg" v-if="fileError.length">{{ fileError.join(', ') }}</div>
            </a>
            <label class="change-file" :for="`fileBlockId${block.id}`">
                <icon-change-file />
            </label>
        </template>
        <label class="label" :for="`fileBlockId${block.id}`" v-else>
            <div class="icon"><icon-file-upload /></div>
            <div class="name">{{ fileName ? fileName : $t('projects.upload_file') }}</div>
            <div class="loader" v-if="percentLoader"><div class="loader-spin"></div>  {{ percentLoader }}%</div>
            <div class="error-msg" v-if="fileError.length">{{ fileError.join(', ') }}</div>
        </label>
        <div class="delete-file" @click="deleteFile">
            <icon-delete />
        </div>

        <input type="file" :id="`fileBlockId${block.id}`" v-on:change="onFileChange"/>
    </div>
</template>

<script>
	import IconFile from '@/components/Icons/File';
	import IconFileUpload from '@/components/Icons/FileUpload';
	import IconChangeFile from '@/components/Icons/Upload';
	import IconDelete from '@/components/Icons/Trash';

    export default {
        inheritAttrs: false,
        components: {
            IconFile,
            IconFileUpload,
            IconChangeFile,
            IconDelete
        },
        props: {
            block: Object
        },
        watch: {
            block: {
                handler(val) {
                    this.fileUrl = this.block.fileUrl;
                    this.fileName = this.block.fileName;
                    this.fileId = this.block.fileId;
                },
            }
        },
        data() {
            return { 
                fileUrl: '',
                fileName: '',
                fileId: '',
                file: '',
                percentLoader: '',
                fileError: []
            }
        },
        mounted() {
            this.fileUrl = this.block.fileUrl;
            this.fileName = this.block.fileName;
            this.fileId = this.block.fileId;
        },
        methods: {
            onFileChange(e) {
                this.fileError = [];

                var files = e.target.files || e.dataTransfer.files;
				if (!files.length) return;

				this.fileName = files[0].name;
				this.file = files[0]

                let formData = new FormData();
                formData.append('file', this.file);

                var config = {
                    onUploadProgress: (progressEvent) => {
                        this.percentLoader = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
                    }
                }; 

                axios.post(`/projects/${this.$route.params.slug}/add-file`, formData, config)
                .then(({data}) => {
                    this.percentLoader = '';
                    this.fileName = data.data.filename;
                    this.fileUrl = data.data.file_path;
                    this.fileId = data.data.id;

                    this.$store.dispatch('blocks/editBlock', { ...this.block, fileUrl: this.fileUrl, fileName: this.fileName, fileId: this.fileId });
                    this.$emit('updateProject');
                })
                .catch(error => {
                    this.percentLoader = '';
					if(error.response) {
                        this.fileError = error.response.data.errors.file;
					}
				})
            },
            deleteFile(e) {
                if(this.fileId) {
                    axios.delete(`/user-files/${this.fileId}`);
                }
                
                this.$emit('deleteBlock', e);
            }
        }
    }
</script>